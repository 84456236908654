<template>
  <div>
    <DataFrom :upload-data="'uploadData'"></DataFrom>
  </div>
</template>

<script>
import DataFrom from './components/DataFrom.vue'
export default {
  components: {
    DataFrom,
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<style scoped></style>
